import axios from "axios";
import {processHttpErrors} from "@/helpers/commonHelper";

export class DataTableService{
    constructor() {
    }
    async loadTable(endpoint, searchFilters){
        return await axios.get(endpoint, {params: searchFilters})
            .then((response) => {
                return {isSuccess: !!response.data, message: response.data? '': 'You are not allowed to perform this action', data: response.data};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', data: null};
            });
    }
}