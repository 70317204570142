<template>
   <div class="row">
       <div :class="this.tableSize">
           <data-table :columns="columns" :data="rows" :order-by="searchFilters.column" :order-dir="searchFilters.dir" :per-page="perPage" :debounce-delay="debounceDelay" @on-table-props-changed="loadTable">
               <div slot="filters" slot-scope="{ perPage }">
                   <div class="row mb-2">
                       <div class="col-sm-2" v-if="showPerPage">
                           <select class="form-control" v-model="searchFilters.length" v-on:change="loadTable()">
                               <option :key="page" v-for="page in perPage">{{ page }}</option>
                           </select>
                       </div>
                       <div class="col-sm-4" v-if="showSearch">
                           <input name="table-search" placeholder="Search" class="form-control" v-model="searchFilters.search" v-on:keyup="loadTable()">
                       </div>
                       <div class="col-sm-6">
                           <button type="button" class="ml-2 mt-1" :class="button.buttonClass" v-for="button in additionalButtons" :key="button.label" v-on:click="button.click()"><i :class="button.labelIconClass"></i> {{button.label}}</button>
                       </div>
                   </div>
               </div>
               <!--<thead slot="header" slot-scope="{ }">
                   <tr>
                       <th @click="sort(column.name)" :key="column.name" v-for="column in columns" :column="column" :currentSortColumn="searchFilters.column">{{column.label}}</th>
                   </tr>
               </thead>-->
               <tbody slot="body" slot-scope="{ data }">
                   <tr :key="item.id" v-for="(item, rowIndex) in data">
                       <td :key="column.name" v-for="(column, columnIndex) in columns" :style="column.style">
                           <data-table-cell
                                   :row="rowIndex"
                                   :column="columnIndex"
                                   :value="item"
                                   :transform="column.transform"
                                   :name="column.name"
                                   :meta="column.meta"
                                   :event="column.event"
                                   :classes="column.classes"
                                   :handler="column.handler"
                                   :comp="column.component">
                           </data-table-cell>
                       </td>
                   </tr>
               </tbody>

               <div slot="pagination" v-if="!showPagination"></div>

            </data-table>
       </div>
   </div>

</template>

<script>
    import Vue from 'vue'
    import DataTable from 'laravel-vue-datatable';
    Vue.use(DataTable);
    import {DataTableService} from "@/services/data-table-service";

    let dataTableService = new DataTableService();
    export default {
        name: 'CustomDataTable',
        data: function(){
            return {
                rows: {},
                sortOrders: {},
            }
        },
        props: {
            tableSize: {
                type: String,
                default: () => ('col-sm-12')
            },
            tableDataUrl: {
                type: String,
            },
            searchFilters: {
                type: Object,
                default: () => ({
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                    draw: 0,
                    page: 1
                })
            },
            showPerPage: {
                type: Boolean,
                default: ()=> true
            },
            showSearch: {
                type: Boolean,
                default: ()=> true
            },
            showPagination: {
                type: Boolean,
                default: ()=> true
            },
            columns: {
                type: Array,
                default: () => ([
                    {label: 'ID', name: 'id', orderable: true},
                ])
            },
            perPage: {
                type: Array,
                default: () => (['10','25', '50', '100', '500'])
            },
            debounceDelay:{
                type: Number,
                default: 2000
            },
            additionalButtons: {
                type: Array,
                default: () => []
            },
            totalColumns: {
                type: Array,
                default: () => []
            }
        },
        components: {
        },
        computed: {

        },
        methods: {
            loadTable: async function (changedData) {
                //console.log(changedData);
                if (changedData) {
                    this.searchFilters.column = changedData.column;
                    this.searchFilters.dir = changedData.dir;
                    this.searchFilters.draw = changedData.draw;
                    this.searchFilters.page = changedData.page;
                }
                if (this.tableDataUrl) {
                    let loader = this.$loading.show({container: this.$refs.formContainer});
                    let response = await dataTableService.loadTable(this.tableDataUrl, this.searchFilters);
                    if (response.isSuccess) {
                        this.rows = response.data;
                        if (this.totalColumns.length > 0) {
                            this.$emit('totals-calculated', this.calculateTotals());
                            //console.log(this.calculateTotals());
                        }
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            },
            calculateTotals: function () {
                let totals = {};
                //console.log(this.rows.data);

                this.totalColumns.forEach(totalColumn => {
                    let total = 0;
                    //console.log(this.rows);
                    this.rows.data.forEach((row) => {
                        //console.log(row);
                        if(row[totalColumn]){
                            total += Number(row[totalColumn]);
                        }
                    });
                    totals[totalColumn] = total;
                });
                return totals;
            }
        },
        mounted() {
            this.loadTable(null);
        },
        created() {

        }
    }
</script>

<style scoped>

</style>
